import React, { useState, useEffect, useReducer, Fragment } from 'react'
import { validate, parse } from 'fast-xml-parser';

const initialState = {
    messageType: ["json", "xml", "text"],
    responseType: ["buyer", "server"], //If there is a response type then determine if we should send the buyers response or our own response
    successMessageKey: '', //The key from the response that will be used to display the success message
    successMessageValue: '', //The value from the response key that the LMS will look for to display the success message
    successMessageResponse: '', //The response from the LMS that will be displayed to the vendor
    failureMessageKey: '', //The key from the response that will be used to display the failure message
    failureMessageResponseType: 'buyer', //If there is a failure message then determine if we should send the buyers response or our own response
    failureMessageResponseKey: '', //If the failure message response type is buyer then we need to know what key to look for in the buyers response
    failureMessageResponse: 'No buyer found', //If the failure message is server then send a generic LMS response. Defaults to 'No buyer found'
    errorMessageKey: '', //The key from the response that will be used to display the error message
    errorMessageResponseType: 'buyer', //If there is an error message then determine if we should send the buyers response or our own response
    errorMessageKey: '', //If the error message response type is buyer then we need to know what key to look for in the buyers response
    errorMessageResponse: 'No buyer found', //If the error message is server then send a generic LMS response. Defaults to 'No buyer found'
    noticeMessageKey: '', //The key from the response that will be used to display the notice message
    noticeMessageResponseType: 'buyer', //If there is a notice message then determine if we should send the buyers response or our own response
    noticeMessageValue: '', //The value from the response key that the LMS will look for to display the notice message
    noticeMessageResponse: 'No buyer found', //The response from the LMS that will be displayed to the vendor
}

const reducer = (state, action) => {
    switch(action.type) {
        case 'SET_MESSAGE_TYPE':
            return {
                ...state,
                messageType: action.payload
            }
        case 'SET_SUCCESS_MESSAGE_KEY':
            return {
                ...state,
                successMessageKey: action.payload
            }
        case 'SET_SUCCESS_MESSAGE_VALUE':
            return {
                ...state,
                successMessageValue: action.payload
            }
        case 'SET_SUCCESS_MESSAGE_RESPONSE':
            return {
                ...state,
                successMessageResponse: action.payload
            }
        case 'SET_FAILURE_MESSAGE_KEY':
            return {
                ...state,
                failureMessageKey: action.payload
            }
        case 'SET_FAILURE_MESSAGE_RESPONSE_TYPE':
            return {
                ...state,
                failureMessageResponseType: action.payload
            }
        case 'SET_FAILURE_MESSAGE_RESPONSE_KEY':
            return {
                ...state,
                failureMessageResponseKey: action.payload
            }
        case 'SET_FAILURE_MESSAGE_RESPONSE':
            return {
                ...state,
                failureMessageResponse: action.payload
            }
        case 'SET_ERROR_MESSAGE_KEY':
            return {
                ...state,
                errorMessageKey: action.payload
            }
        case 'SET_ERROR_MESSAGE_RESPONSE_TYPE':
            return {
                ...state,
                errorMessageResponseType: action.payload
            }
        case 'SET_ERROR_MESSAGE_RESPONSE_KEY':
            return {
                ...state,
                errorMessageResponseKey: action.payload
            }
        case 'SET_ERROR_MESSAGE_RESPONSE':
            return {
                ...state,
                errorMessageResponse: action.payload
            }
        case 'SET_NOTICE_MESSAGE_KEY':
            return {
                ...state,
                noticeMessageKey: action.payload
            }
        case 'SET_NOTICE_MESSAGE_RESPONSE_TYPE':
            return {
                ...state,
                noticeMessageResponseType: action.payload
            }
        case 'SET_NOTICE_MESSAGE_VALUE':
            return {
                ...state,
                noticeMessageValue: action.payload
            }
        case 'SET_NOTICE_MESSAGE_RESPONSE':
            return {
                ...state,
                noticeMessageResponse: action.payload
            }
        case 'RESET':
            return initialState
        default:
            return state
    }
}

function BuyerMessages(props) {
    const [state, dispatch] = useReducer(reducer, initialState)

    return(
        <div>
            <div className="card">
            <div className="card-body">
            <SuccessMessages 
                messageType={state.messageType}
                successMessageKey={state.successMessageKey}
                successMessageValue={state.successMessageValue}
                successMessageResponse={state.successMessageResponse}
                dispatch={dispatch}
            />
            </div>
            </div>
            <FailureMessages
                messageType={state.messageType}
                responseType={state.responseType}
                failureMessageKey={state.failureMessageKey}
                failureMessageResponseType={state.failureMessageResponseType}
                failureMessageResponseKey={state.failureMessageResponseKey}
                failureMessageResponse={state.failureMessageResponse}
                dispatch={dispatch}
            />
            <ErrorMessages
                messageType={state.messageType}
                responseType={state.responseType}
                errorMessageKey={state.errorMessageKey}
                errorMessageResponseType={state.errorMessageResponseType}
                errorMessageResponseKey={state.errorMessageResponseKey}
                errorMessageResponse={state.errorMessageResponse}
                dispatch={dispatch}
            />
            <NoticeMessages
                messageType={state.messageType}
                responseType={state.responseType}
                noticeMessageKey={state.noticeMessageKey}
                noticeMessageResponseType={state.noticeMessageResponseType}
                noticeMessageValue={state.noticeMessageValue}
                noticeMessageResponse={state.noticeMessageResponse}
                dispatch={dispatch}
            />
        </div>
    )
}

const SuccessMessages = (props) => {
    const [messageType, setMessageType] = useState('')
    const [value, setValue] = useState('')
    const [json, setJson] = useState('')
    const [xml, setXml] = useState()

    const validate = (e, type) => {
        e.preventDefault()
        if(type == 'json'){
            const valid = isJsonString(value)
            if(valid){
                alert('Valid JSON')
                setJson(JSON.parse(value))
            } else {
                alert('Invalid JSON')
            }
        } else if(type == 'xml'){
            const valid = isValidXML(value)
            if(valid){
                alert('Valid XML')
            } else {
                alert('Invalid XML')
            }
        }
    }

    const handleChecked = (e) => {
        if(e.target.checked){
            props.dispatch({type: 'SET_SUCCESS_MESSAGE_KEY', payload: e.target.value})
        } else {
            props.dispatch({type: 'SET_SUCCESS_MESSAGE_KEY', payload: ''})
        }
    }


    return(
        //3 column set up for success messages
            <div className="row">
                <div className="col">
                    <form>
                        <fieldset>
                            <legend>Success Messages</legend>
                                <select className="form-control" value={messageType} onChange={(e) => setMessageType(e.target.value)}>
                                    <option value="">Select Message Type</option>
                                    {props.messageType.map((type, index) => {
                                        return <option key={index} value={type}>{type}</option>
                                    }
                                    )}
                                </select>
                                {messageType == 'json' || messageType == 'xml' ?
                                    <MessageJson
                                        setValue={setValue}
                                        validate={validate}
                                        messageType={messageType}
                                    />
                                : ''}
                                {json ? 
                                    <JsonWalkThrough
                                        json={json}
                                        handleChecked={handleChecked}
                                    />
                                : ''}
                        </fieldset>
                    </form>
                </div>
                <div className="col">
                    <form>
                        <fieldset>
                            <legend>Response Type</legend>
                            <input type="text" className="form-control" placeholder="Success Message Key" value={props.successMessageKey} onChange={(e) => props.dispatch({type: 'SET_SUCCESS_MESSAGE_KEY', payload: e.target.value})} />
                            <input type="text" className="form-control" placeholder="Success Message Value" value={props.successMessageValue} onChange={(e) => props.dispatch({type: 'SET_SUCCESS_MESSAGE_VALUE', payload: e.target.value})} />
                        </fieldset>
                    </form>
                </div>
            </div>
    )
}

const FailureMessages = (props) => {
    return(
        //3 column set up for failure messages
        <div className="d-flex justify-content-between">
            <select className="form-control" value={props.messageType} onChange={(e) => props.dispatch({type: 'SET_MESSAGE_TYPE', payload: e.target.value})}>
                <option value="">Select Message Type</option>
                {props.messageType.map((type, index) => {
                    return <option key={index} value={type}>{type}</option>
                }
                )}
            </select>
            <input type="text" className="form-control" placeholder="Failure Message Key" value={props.failureMessageKey} onChange={(e) => props.dispatch({type: 'SET_FAILURE_MESSAGE_KEY', payload: e.target.value})} />
            <select className="form-control" value={props.failureMessageResponseType} onChange={(e) => props.dispatch({type: 'SET_FAILURE_MESSAGE_RESPONSE_TYPE', payload: e.target.value})}>
                <option value="">Select Response Type</option>  
                {props.responseType.map((type, index) => {
                    return <option key={index} value={type}>{type}</option>
                }
                )}
            </select>
            <input type="text" className="form-control" placeholder="Failure Message Response Key" value={props.failureMessageResponseKey} onChange={(e) => props.dispatch({type: 'SET_FAILURE_MESSAGE_RESPONSE_KEY', payload: e.target.value})} />
            {props.failureMessageResponseType === 'server' ?
                <input type="text" className="form-control" placeholder="Failure Message Response" value={props.failureMessageResponse} onChange={(e) => props.dispatch({type: 'SET_FAILURE_MESSAGE_RESPONSE', payload: e.target.value})} />
            : ''}
        </div>
    )
}

const ErrorMessages = (props) => {
    return(
        //3 column set up for error messages
        <div className="d-flex justify-content-between">
            <select className="form-control" value={props.messageType} onChange={(e) => props.dispatch({type: 'SET_MESSAGE_TYPE', payload: e.target.value})}>
                <option value="">Select Message Type</option>
                {props.messageType.map((type, index) => {
                    return <option key={index} value={type}>{type}</option>
                }
                )}
            </select>
            <input type="text" className="form-control" placeholder="Error Message Key" value={props.errorMessageKey} onChange={(e) => props.dispatch({type: 'SET_ERROR_MESSAGE_KEY', payload: e.target.value})} />
            <select className="form-control" value={props.errorMessageResponseType} onChange={(e) => props.dispatch({type: 'SET_ERROR_MESSAGE_RESPONSE_TYPE', payload: e.target.value})}>
                <option value="">Select Response Type</option>
                {props.responseType.map((type, index) => {
                    return <option key={index} value={type}>{type}</option>
                }
                )}
            </select>
            <input type="text" className="form-control" placeholder="Error Message Response Key" value={props.errorMessageResponseKey} onChange={(e) => props.dispatch({type: 'SET_ERROR_MESSAGE_RESPONSE_KEY', payload: e.target.value})} />
            {props.errorMessageResponseType === 'server' ?
                <input type="text" className="form-control" placeholder="Error Message Response" value={props.errorMessageResponse} onChange={(e) => props.dispatch({type: 'SET_ERROR_MESSAGE_RESPONSE', payload: e.target.value})} />
            : ''}
        </div>
    )
}

const NoticeMessages = (props) => {
    return(
        //3 column set up for notice messages
        <div className="d-flex justify-content-between">
            <select className="form-control" value={props.messageType} onChange={(e) => props.dispatch({type: 'SET_MESSAGE_TYPE', payload: e.target.value})}>
                <option value="">Select Message Type</option>
                {props.messageType.map((type, index) => {
                    return <option key={index} value={type}>{type}</option>
                }
                )}
            </select>
            <input type="text" className="form-control" placeholder="Notice Message Key" value={props.noticeMessageKey} onChange={(e) => props.dispatch({type: 'SET_NOTICE_MESSAGE_KEY', payload: e.target.value})} />
            <input type="text" className="form-control" placeholder="Notice Message Value" value={props.noticeMessageValue} onChange={(e) => props.dispatch({type: 'SET_NOTICE_MESSAGE_VALUE', payload: e.target.value})} />
        </div>  
    )
}

function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

function isValidXML(str){
    try {
        validate(str)
    } catch (e) {
        return false;
    }
    return true;
}

const MessageJson = props => {
    return (
        <div>
            <div clasNames="form-floating">
            <textarea className="form-control" placeholder="Enter a JSON response string" id="floatingTextarea2" style={{height: "200px"}} onChange={e => props.setValue(e.target.value)}></textarea>
                <label for="floatingTextarea2">Enter a JSON string for validation</label>
            </div>
            <button className="btn btn-primary" onClick={e => props.validate(e, props.messageType)}>Validate</button>
        </div>
    )
}

const JsonWalkThrough = props => {

    return(
        <div>
            <ol>
                <li>What is the key where your success </li>
            </ol>
        </div>
    )
}


const MessageJsonFields = props => {
    return (
        Object.keys(props.json).map((key, index) => { 
            return (
                <div key={index}>
                    {Object.keys(props.json[key]).map(items => items).length > 0 ? 
                        <div>
                            <p>This string is wrapped in this key<strong>{key}</strong></p>
                            {Object.keys(props.json[key]).map((items,key) => {
                                return (
                                    <form>
                                        <fieldset>
                                            <legend>Which key should we use for validation</legend>
                                            <div className="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" onChange={e =>  props.handleChecked(e)} />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    {items}
                                                </label>
                                            </div>
                                        </fieldset>
                                    </form>
                                )})}
                        </div>
                : 
                <form>
                    <fieldset>
                        <legend>Which key should we use for validation</legend>
                        <div className="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" onChange={e =>  props.handleChecked(e)} />
                            <label class="form-check-label" for="flexCheckChecked">
                                {key}
                            </label>
                        </div>
                    </fieldset>
                </form>
                }
                </div>
            )
        }) 
    )
}



const keyify = (obj, prefix = '') => 
  Object.keys(obj).reduce((res, el) => {
    if( Array.isArray(obj[el]) ) {
      return res;
    } else if( typeof obj[el] === 'object' && obj[el] !== null ) {
      return [...res, ...keyify(obj[el], prefix + el + '.')];
    }
    return [...res, prefix + el];
  }, []);

export default BuyerMessages
