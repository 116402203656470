import React, {useState, useEffect} from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Progress } from 'reactstrap';
import { processQueues } from '../util/db'
function ProcessQueue(){
    const [field, setField] = useState([])

    useEffect(() => {
        fetchData()
        // Refresh data every 10 seconds
        const interval = setInterval(fetchData, 10000)
        return () => clearInterval(interval)
    }, []);

    const fetchData = async () => {
        const get = await processQueues('', 'search')
        // Convert Unix timestamp to human-readable date string
        const updatedData = get.map(job => ({
            ...job,
            pushed_at: new Date(job.pushed_at * 1000).toLocaleString()
        }))
        setField(updatedData)
    }

    return (
        <div>
            {field.map((job) => (
                <Card key={job.id} style={{ marginBottom: '20px' }}>
                    <CardBody>
                        <CardTitle tag="h5">Job ID: {job.id}</CardTitle>
                        <CardSubtitle tag="h6" className="mb-2 text-muted">Channel: {job.channel}</CardSubtitle>
                        <CardText>
                            <div>Started: {job.pushed_at}</div>
                            <div>Priority: {job.priority}</div>
                            <div>Attempts: {job.attempt}</div>
                            {/* Placeholder for job status, replace with actual data */}
                            <div>Status: {job.done_at ? "Completed" : "Processing"}</div>
                        </CardText>
                    </CardBody>
                </Card>
            ))}
        </div>
    )
}

export default ProcessQueue
