import React, { Component } from 'react'

export default class Offer extends Component {

    render(){
        return(
            <div>
                All offers
            </div>    
        )
    }
}