import React from 'react'

function VendorDashboard(){
    return (
        <div>

         </div>   
    )
}

export default VendorDashboard